import { type FC } from 'react';
import { memo } from 'react';
import { Form } from 'react-final-form';
import { useReportProblemCardsOptions } from '@/components/platform/LikeRating/hooks/useReportProblemCardsOptions';
import { isNotEmpty } from '@/controllers/forms/forms.validator/validationRules';
import { type FormValues } from '@/components/platform/LikeRating/ReportProblemForm.typedefs';
import { CommentFormField } from '@/components/platform/LikeRating/Comment/CommentFormField';
import { ReportProblemCardField } from '@/components/platform/LikeRating/ReportProblemCard/ReportProblemCardFields';

interface Props {
  submitCallback: (data: FormValues) => void;
  loading: boolean;
  openComment: () => void;
  shouldShowComment: boolean;
  isModalOpen?: boolean;
}

export const ReportProblemForm: FC<Props> = memo(({
  submitCallback,
  loading,
  shouldShowComment,
  openComment,
  isModalOpen,
}) => {
  const feedbackCards = useReportProblemCardsOptions(
    openComment,
  );

  const shouldRenderFeedbackCards = !shouldShowComment && isModalOpen;

  return (
    <Form<FormValues>
      onSubmit={submitCallback}
      render={({ values, handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          {shouldRenderFeedbackCards && (
            feedbackCards.map((card) => (
              <ReportProblemCardField
                key={card.type}
                feedbackCard={card}
                change={form.change}
                dataQa={card.dataQa}
              />
            ))
          )}

          {shouldShowComment && (
            <CommentFormField
              loading={loading}
              disabled={!isNotEmpty(values.comment)}
            />
          )}
        </form>
      )}
    />
  );
});
