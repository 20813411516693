import { type FC } from 'react';
import { memo } from 'react';
import { usePlatformTheme } from '@/components/common/ThemeContext';
import { ColorThemePreference } from '@/components/common/ThemeContext/typedefs';
import { PlatformThemeSelector } from '@/components/platform/PlatformThemeSwitcher/PlatformThemeSelector';
import { NoSSR } from '@/components/common/NoSSR';
import styles from './PlatformThemeSwitcher.module.scss';

export const PlatformThemeSwitcher: FC = memo(() => {
  const {
    setColorThemePreference,
    colorThemePreference,
  } = usePlatformTheme();

  return (
    <NoSSR>
      <div
        className={styles.colorThemePreferenceOptions}
      >
        {Object.values(ColorThemePreference).map((preference) => (
          <PlatformThemeSelector
            key={preference}
            selectorPreference={preference}
            chosenThemePreference={colorThemePreference}
            selectThemePreference={setColorThemePreference}
          />
        ))}
      </div>
    </NoSSR>
  );
});
