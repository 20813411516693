import { useCallback, type FC } from 'react';
import { memo } from 'react';
import { cn } from '@/lib/classNames';
import { Dropdown, type RenderContentProps } from '@/components/common/Dropdown';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { ProfileDropdownContentItems } from '@/components/platform/ProfileDropdown/ProfileDropdownContentItems';
import { Selectors } from '@/lib/selectors';
import { ProfileAvatar } from '../ProfileAvatar';
import styles from './ProfileDropdown.module.scss';

export const ProfileDropdown: FC = memo(
  () => {
    const [authUser] = useAuthUser({ ssr: true });

    const renderTrigger = useCallback((onClick) => (
      <button
        className={styles.triggerButton}
        onClick={onClick}
        data-qa='dropdown-open-button'
      >
        <ProfileAvatar />
      </button>
    ), []);

    const renderContent = useCallback(({ isActive }: RenderContentProps) => (
      <ul
        data-qa='dropdown-content'
        className={cn(
          styles.dropdownContent,
          {
            [Selectors.DropdownOpened]: isActive,
          },
        )}
      >
        <ProfileDropdownContentItems isOpened={isActive} />
      </ul>
    ), []);

    if (!authUser) {
      return null;
    }

    return (
      <div className={styles.wrapper} data-qa='profile-dropdown'>
        <Dropdown
          isFullWidth
          className={styles.container}
          renderTrigger={renderTrigger}
          renderContent={renderContent}
        />
      </div>
    );
  },
);
