import {
  type FC,
  type ReactChild,
  type ReactElement,
  useCallback,
} from 'react';
import { cn } from '@/lib/classNames';
import { useClickOutside } from '@/hooks/useClickOutside';
import { DropdownArrows } from '@/components/common/DropdownArrows';
import { typography } from '@/components/ui/typography';
import { Selectors } from '@/lib/selectors';
import {
  useRouteChangeCallback,
} from '@/controllers/router/router.hooks/useRouteChangeCallback';
import styles from './Dropdown.module.scss';

export interface RenderContentProps {
  isActive: boolean;
}

export interface DropdownProps {
  title?: ReactChild;
  large?: boolean;
  icon?: ReactElement;
  isFullWidth?: boolean;
  renderContent: ({ isActive }: RenderContentProps) => ReactChild;
  renderTrigger?: (onClick: () => void) => ReactElement;
  className?: string;
  sendEvent?: () => void;
}

export const Dropdown: FC<DropdownProps> = ({
  title,
  large,
  icon,
  isFullWidth,
  renderContent,
  renderTrigger,
  className,
  sendEvent,
}) => {
  const { ref, active, setActive } = useClickOutside(false);

  const closeDropdown = useCallback(() => {
    setActive(false);
  }, [setActive]);

  useRouteChangeCallback(closeDropdown);

  const toggle = useCallback(
    () => {
      setActive((isActive) => {
        if (sendEvent && !isActive) {
          sendEvent();
        }

        return !isActive;
      });
    },
    [setActive, sendEvent],
  );

  return (
    <div
      ref={ref}
      className={className}
    >
      {renderTrigger
        ? renderTrigger(toggle)
        : (
          <button
            onClick={toggle}
            className={cn(
              styles.trigger,
              {
                [styles.large]: large,
                [styles.fullWidth]: isFullWidth,
                [Selectors.DropdownOpened]: active,
              },
            )}
            data-qa='dropdown-open-button'
          >
            {icon}

            <div
              data-qa='dropdow-text-and-arrow-container'
              className={styles.textAndArrowContainer}
            >
              {title && (
                <span
                  data-qa='dropdown-arrow-button'
                  className={cn(
                    styles.triggerText,
                    typography.platformH4,
                  )}
                >
                  {title}
                </span>
              )}

              <DropdownArrows active={active} />
            </div>
          </button>
        )}

      {renderContent({
        isActive: active,
      })}
    </div>
  );
};
