import { type FC } from 'react';
import { useMemo } from 'react';
import { useGetPrivaciesDocs } from '@/controllers/landing/landing.helpers';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import styles from './Policies.module.scss';

export const Policies: FC = () => {
  const { t } = useTranslation(I18N_CODES.common);
  const { termsOfUse, privacyPolicy, cookiesPolicy } = useGetPrivaciesDocs();

  const linkItems = useMemo(() => (
    [
      {
        id: 1,
        title: `${I18N_CODES.common}:privacy_policy_dropdown`,
        link: privacyPolicy,
      },
      {
        id: 2,
        title: `${I18N_CODES.common}:terms_of_use_dropdown`,
        link: termsOfUse,
      },
      {
        id: 3,
        title: `${I18N_CODES.common}:cookies_policy_dropdown`,
        link: cookiesPolicy,
      },
    ]
  ), [termsOfUse, privacyPolicy, cookiesPolicy]);

  return (
    <div className={cn(styles.linkContainer, typography.platformTextSmall)} data-qa="policies-links-block">
      {linkItems.map((item) => (
        <a
          key={item.id}
          href={item.link}
          data-qa="policy-link"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          {t(item.title)}
        </a>
      ))}
    </div>
  );
};
