import { type FC, memo, useMemo } from 'react';
import { ROUTES } from '@/controllers/router/router.contants';
import { IconWallet } from '@/components/ui/icons/IconWallet';
import { I18N_CODES } from '@/lib/constants/general';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useTranslation } from '@/middleware/i18n';
import { IconCareer } from '@/components/ui/icons/IconCareer';
import { Features } from '@/controllers/features/features.constants';
import { IconUser } from '@/components/ui/icons/IconUser';
import { isUserHaveAccessToCareer } from '@/controllers/user/user.utils/userStatus.selectors';
import { IconSettingsBold } from '@/components/ui/icons/IconSettingsBold';
import { isFlexStudent } from '@/controllers/user/user.utils/isFlexStudent';
import {
  IconMateAcademyStroke,
} from '@/components/ui/icons/product/Mate/IconMateAcademyStroke';
import { IconBadgeProStroke } from '@/components/ui/icons/IconBadgeProStroke';
import { useSubscriptionProductFeatures } from '@/components/platform/SubscriptionProduct/hooks/useSubscriptionProductFeatures';
import { type ProfileDropdownItemI, ProfileDropdownItemType } from '@/components/platform/ProfileDropdown/typedefs';
import { useLogoutDropdownItem } from '@/components/platform/ProfileDropdown/hooks/useLogoutDropdownItem';
import { ProfileDropdownItem } from '@/components/platform/ProfileDropdown/ProfileDropdownItem';
import { DropdownSwitchThemeItem } from '@/components/platform/ProfileDropdown/DropdownSwitchThemeItem';
import { ProfileShortInfo } from '@/components/platform/ProfileShortInfo';
import { IconSize } from '@/components/ui/icons/BaseIcon';
import { useRequestFeatureItem } from '@/components/platform/ProfileDropdown/hooks/useRequestFeatureDropdownItem';
import { Policies } from '@/components/platform/ProfileDropdown/Policies';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { EMPTY_ARRAY } from '@/constants';
import { useCheckAccessToAdminTools } from '../AdminTools/hooks/useCheckAccessToAdminTools';

interface Props {
  isOpened?: boolean;
}

export const ProfileDropdownContentItems: FC<Props> = memo<Props>(
  ({ isOpened }) => {
    const [authUser] = useAuthUser({ ssr: true });
    const features = useFeatures();
    const { t } = useTranslation([I18N_CODES.profile]);

    const { hasAccessToAdminTools } = useCheckAccessToAdminTools();

    const logOutItem = useLogoutDropdownItem();

    const requestFeatureItem = useRequestFeatureItem();

    const {
      isAvailable: isSubscriptionProductEnabled,
    } = useSubscriptionProductFeatures();

    const subscriptionButtonCode = isSubscriptionProductEnabled
      ? 'profile.dropdown_my_subscription'
      : 'profile.dropdown_subscriptions';

    const {
      isSubscriptionProductDomain,
      isKnowelyProduct,
    } = useSubDomainContext();

    const shouldShowRequestFeatureItem = !isKnowelyProduct;

    const dropdownItems = useMemo<ProfileDropdownItemI[]>(
      () => {
        if (!authUser) {
          return EMPTY_ARRAY;
        }

        return [
          {
            id: 1,
            type: ProfileDropdownItemType.Redirect,
            text: t(`${I18N_CODES.profile}:profile.dropdown_personal`),
            icon: <IconUser size={IconSize.Small} />,
            route: ROUTES.user.profile,
            dataQA: 'profile-dropdown-profile-item',
          },
          {
            id: 4,
            type: ProfileDropdownItemType.Redirect,
            text: t(`${I18N_CODES.profile}:career.tab_title`),
            condition: isUserHaveAccessToCareer(authUser.studentStatus),
            icon: <IconCareer size={IconSize.Small} />,
            route: ROUTES.user.career,
            dataQA: 'profile-dropdown-career-item',
          },
          {
            id: 5,
            type: ProfileDropdownItemType.Redirect,
            text: t(`${I18N_CODES.profile}:${subscriptionButtonCode}`),
            icon: <IconBadgeProStroke size={IconSize.Small} />,
            route: ROUTES.subscriptions.index,
            dataQA: 'profile-dropdown-subscriptions-item',
          },
          {
            id: 6,
            type: ProfileDropdownItemType.Redirect,
            text: t(`${I18N_CODES.profile}:profile.dropdown_payments`),
            condition: !isSubscriptionProductEnabled
              && features.isEnabled(Features.PaymentsMethods)
              && isFlexStudent(authUser),
            icon: <IconWallet size={IconSize.Small} />,
            route: ROUTES.user.wallet,
            dataQA: 'profile-dropdown-wallet-item',
          },
          {
            id: 7,
            type: ProfileDropdownItemType.Redirect,
            text: t(`${I18N_CODES.profile}:admin_tools`),
            condition: hasAccessToAdminTools,
            icon: <IconMateAcademyStroke size={IconSize.Small} />,
            route: ROUTES.user.adminTools,
            dataQA: 'profile-dropdown-admin-tools-item',
          },
          {
            id: 8,
            type: ProfileDropdownItemType.Redirect,
            text: t(`${I18N_CODES.profile}:profile.dropdown_settings`),
            icon: <IconSettingsBold size={IconSize.Small} />,
            route: ROUTES.user.settings,
            dataQA: 'profile-dropdown-settings-item',
          },
        ];
      },
      [
        t,
        authUser,
        features,
        hasAccessToAdminTools,
        subscriptionButtonCode,
        isSubscriptionProductEnabled,
      ],
    );

    if (!authUser) {
      return null;
    }

    return (
      <>
        <ProfileShortInfo />

        {dropdownItems.map((item) => (
          <ProfileDropdownItem
            key={item.id}
            item={item}
          />
        ))}

        {isOpened && (
          <DropdownSwitchThemeItem />
        )}

        {
          shouldShowRequestFeatureItem && (
            <ProfileDropdownItem
              key={requestFeatureItem.id}
              item={requestFeatureItem}
            />
          )
        }

        <ProfileDropdownItem
          key={logOutItem.id}
          item={logOutItem}
        />

        {isSubscriptionProductDomain && <Policies />}
      </>
    );
  },
);
