import { type FC } from 'react';
import { memo } from 'react';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { PlatformThemeSwitcher } from '@/components/platform/PlatformThemeSwitcher';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import styles from './DropdownSwitchThemeItem.module.scss';

export const DropdownSwitchThemeItem: FC = memo(() => {
  const { isEnabled, DarkMode } = useFeatures();
  const { t } = useTranslation([I18N_CODES.profile]);
  const canSwitchTheme = isEnabled(DarkMode);

  if (!canSwitchTheme) {
    return null;
  }

  return (
    <div className={styles.wrapper} data-qa="dark-theme-item">
      <h3
        className={cn(
          styles.textItem,
          typography.platformH3,
          'c-text-platform-default',
        )}
      >
        {t(`${I18N_CODES.profile}:profile.theme_switcher`)}
      </h3>

      <PlatformThemeSwitcher />
    </div>
  );
});
