import type { FC, ReactChild } from 'react';
import { memo } from 'react';
import { NoSSR } from '@/components/common/NoSSR';
import { cn } from '@/lib/classNames';
import { SignedAsUser } from '@/components/platform/SignedAsUser';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { SidebarHeader } from '@/components/platform/SidebarHeader';
import { ProfileDropdown } from '@/components/platform/ProfileDropdown/ProfileDropdown';
import { TimeTracker } from '@/components/platform/TimeTracker';
import { MobileHeaderBackButton } from '@/components/platform/Header/MobileHeaderBackButton';
import { useAchievementUnlocked } from '@/components/platform/Header/hooks/useAchievementUnlocked';
import { Notifications } from '@/components/platform/Notifications';
import { StreaksHeaderWidget } from '@/components/platform/Streaks/components/StreaksHeaderWidget';
import { useHasAccess } from '@/controllers/user/user.hooks/useHasAccess';
import { Permission } from '@/controllers/user/Permission';
import { useWindowSize } from '@/hooks/useWindowSize';
import { MINIMAL_TABLET_WIDTH } from '@/constants/breakpoints';
import { MobileSidebarToggleButton } from '@/components/platform/Header/components/MobileSidebarToggleButton/MobileSidebarToggleButton';
import { GlobalSearchButton } from '@/components/platform/GlobalSearch/components/GlobalSearchButton';
import i18Next from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { BecomeStudentButton } from '@/components/platform/PlatformConsultation/BecomeStudentButton';
import { Divider, DividerReferrals } from '@/components/ui/Divider/Divider';
import { useBecomeStudentButton } from '@/components/platform/PlatformConsultation/useBecomeStudentButton';
import { PaymentIssueHeaderPill } from '@/components/paymentProviders/Stripe/PaymentIssueHeaderPill/PaymentIssueHeaderPill';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useShouldRenderMiniBanner } from '@/components/platform/Header/hooks/useShouldRenderMiniBanner';
import { useIsFeaturesEnabled } from '@/controllers/features/features.hooks/useIsFeaturesEnabled';
import { SubDomainFeatures } from '@/controllers/features/subDomainFeature.config';
import { useIsSubscriptionLimitationEnabled } from '@/components/platform/SubscriptionProduct/hooks/useIsSubscriptionLimitationEnabled';
import { KnowelyUnlockCoursesButton } from '@/components/platform/SubscriptionProduct/components/KnowelyUnlockCoursesButton/KnowelyUnlockCoursesButton';
import { GamificationModal } from '@/components/ui/GamificationModal';
import styles from './Header.module.scss';

type Props = {
  HeaderComponents?: ReactChild;
  isBurgerVisible?: boolean;
  shouldAddStylesForMobile?: boolean;
  hasBackButton?: boolean;
  hideOnMobileScreen?: boolean;
};

export const Header: FC<Props> = memo(
  ({
    HeaderComponents,
    isBurgerVisible = true,
    shouldAddStylesForMobile = false,
    hasBackButton = false,
    hideOnMobileScreen = false,
  }) => {
    const [authUser] = useAuthUser({ ssr: true });

    const {
      canShowBecomeStudentButton,
    } = useBecomeStudentButton();
    const shouldShowKnowelyUnlockButton = useIsSubscriptionLimitationEnabled();
    const router = i18Next.useRouter();

    const isGlobalSearchEnabled = useIsFeaturesEnabled(
      SubDomainFeatures.LmsContentSearch,
    );

    const shouldShowUnlockContentButton = canShowBecomeStudentButton
      || shouldShowKnowelyUnlockButton;
    const UnlockContentButtonComponent = shouldShowKnowelyUnlockButton
      ? KnowelyUnlockCoursesButton
      : BecomeStudentButton;

    const isNotificationsMobilePageOpened = router.route === ROUTES.user
      .notifications;

    const isStreaksMobilePageOpened = router.route === ROUTES.user.streaks;

    const { isSubscriptionProductDomain } = useSubDomainContext();

    const [width] = useWindowSize();

    useAchievementUnlocked();

    const hasTimetrackerAccess = useHasAccess(Permission.Timetracker);

    const [, setIsFirstVisit] = useLocalStorage<boolean | null>(
      LOCAL_STORAGE_KEYS.firstPlatformVisit,
      null,
      (v) => {
        if (v === null) {
          setIsFirstVisit(true);
        } else if (v) {
          setIsFirstVisit(false);
        }
      },
    );

    const shouldApplyStyleWithBanner = useShouldRenderMiniBanner();

    if (!authUser) {
      return null;
    }

    const isSmallScreen = width <= MINIMAL_TABLET_WIDTH;
    const shouldHideMobileLinks = isSmallScreen && !!HeaderComponents;

    return (
      <>
        <header
          className={
            cn(styles.wrapper, {
              [styles.hideOnMobileScreen]: hideOnMobileScreen,
              [styles.withMobileAppMiniBanner]: shouldApplyStyleWithBanner,
            })
          }
          data-qa="header"
        >
          <SidebarHeader
            isBurgerVisible={isBurgerVisible}
            shouldAddStylesForMobile={shouldAddStylesForMobile}
          />

          {HeaderComponents}

          <div className={styles.buttonsWrapper}>
            <SignedAsUser />

            {isSubscriptionProductDomain
              && <PaymentIssueHeaderPill />}

            {hasTimetrackerAccess && (
              <TimeTracker />
            )}

            {
              isGlobalSearchEnabled && (
                <GlobalSearchButton />
              )
            }

            {!isSmallScreen && shouldShowUnlockContentButton && (
              <div className="flex-container">
                <UnlockContentButtonComponent />

                <Divider referral={DividerReferrals.Vertical} />
              </div>
            )}

            <div className={cn({
              [styles.hiddenOnMobile]: shouldHideMobileLinks,
            })}
            >
              <StreaksHeaderWidget
                isMobilePageOpened={isStreaksMobilePageOpened}
              />
            </div>

            <NoSSR>
              <div className={cn({
                [styles.hiddenOnMobile]: shouldHideMobileLinks,
              })}
              >
                <Notifications
                  isMobilePageOpened={isNotificationsMobilePageOpened}
                />
              </div>
            </NoSSR>

            <Divider
              referral={DividerReferrals.Vertical}
              className={styles.notificationsDivider}
            />

            <ProfileDropdown />

            {hasBackButton && (
              <MobileHeaderBackButton />
            )}
          </div>

          {isSmallScreen && (
            <MobileSidebarToggleButton
              isBurgerVisible={isBurgerVisible}
              shouldRenderBackButton={
                isNotificationsMobilePageOpened || isStreaksMobilePageOpened
              }
            />
          )}
        </header>

        <GamificationModal />
      </>
    );
  },
);
