import { memo } from 'react';
import { useIsOpenState } from '@/hooks/useIsOpenState';
import styles from '@/components/platform/Markdown/components/common.module.scss';

export const Details = memo(({ children }) => {
  const {
    isOpen: hintState,
    close: setHintState,
  } = useIsOpenState({ initialState: false });

  return (
    <details
      className={styles.details}
      open={hintState}
      onToggle={setHintState}
    >
      {children}
    </details>
  );
});
