import { type FC } from 'react';
import { memo } from 'react';
import { type ProfileDropdownItemI, ProfileDropdownItemType } from '@/components/platform/ProfileDropdown/typedefs';
import {
  ProfileDropdownActionItem,
} from '@/components/platform/ProfileDropdown/ProfileDropdownItem/components/ProfileDropdownActionItem';
import {
  ProfileDropdownRedirectItem,
} from '@/components/platform/ProfileDropdown/ProfileDropdownItem/components/ProfileDropdownRedirectItem';

type Props = {
  item: ProfileDropdownItemI;
};

export const ProfileDropdownItem: FC<Props> = memo(
  ({ item }) => {
    if (item.condition === false) {
      return null;
    }

    switch (item.type) {
      case ProfileDropdownItemType.Redirect:
        return (
          <ProfileDropdownRedirectItem item={item} />
        );
      case ProfileDropdownItemType.Action:
        return (
          <ProfileDropdownActionItem item={item} />
        );
      default:
        return null;
    }
  },
);
