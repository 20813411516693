import { useCallback, useMemo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  type ProfileDropdownActionItemI,
  ProfileDropdownItemType,
} from '@/components/platform/ProfileDropdown/typedefs';
import { colorTokens } from '@/components/ui/colorTokens';
import { IconSize } from '@/components/ui/icons/BaseIcon';
import { IconBulb } from '@/components/ui/icons/IconBulb';
import { IconExternalLink } from '@/components/ui/icons/IconExternalLink';
import { MATE_CANNY_PATH, RedirectToCannySource } from '@/components/platform/LikeRating/ReportProblem.constants';
import { analyticsSDK } from '@/controllers/analytics';

export const useRequestFeatureItem = (): ProfileDropdownActionItemI => {
  const { t } = useTranslation([I18N_CODES.profile]);
  const sentRedirectToCannyEvent = useCallback(() => {
    analyticsSDK.reportProblem.sendRedirectToCannyEvent({
      source: RedirectToCannySource.ProfileDropdown,
    });
  }, []);
  const redirect = useCallback((url: string) => window.open(url, '_blank'), []);

  const requestFeatureTitle = t(`${I18N_CODES.profile}:request_feature_title`);

  const onAction = useCallback(() => {
    sentRedirectToCannyEvent();
    redirect(MATE_CANNY_PATH);
  }, [sentRedirectToCannyEvent, redirect]);

  return useMemo(() => ({
    id: 110,
    type: ProfileDropdownItemType.Action,
    text: requestFeatureTitle,
    route: MATE_CANNY_PATH,
    icon: <IconBulb size={IconSize.Small} color={colorTokens.icon} />,
    onAction,
    iconOnHover: <IconExternalLink size={IconSize.Small} color={colorTokens['icon-tertiary']} />,
    dataQA: 'request-feature',
  }), [onAction, requestFeatureTitle]);
};
