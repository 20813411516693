import {
  memo,
  type CSSProperties,
  type ReactNode,
} from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useMarkdownTheme } from '@/components/platform/Markdown/theme/useMarkdownTheme';
import { EMPTY_OBJECT } from '@/constants';

export type CodeProps = {
  className?: string;
  children: ReactNode;
  customHighlighterStyles?: CSSProperties;
};

export const Code = memo<CodeProps>(({
  className,
  children,
  customHighlighterStyles = EMPTY_OBJECT,
}) => {
  const markDownTheme = useMarkdownTheme();

  const match = /language-(\w+)/.exec(className || '');

  return match
    ? (
      <SyntaxHighlighter
        language={match[1]}
        PreTag="div"
        style={markDownTheme}
        customStyle={{
          fontSize: '14px',
          border: 'none',
          overflowX: 'auto',
          ...customHighlighterStyles,
        }}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    )
    : (
      <code className={className}>
        {children}
      </code>
    );
});
