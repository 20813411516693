import { useCallback, type FC } from 'react';
import { memo } from 'react';
import { ColorThemePreference } from '@/components/common/ThemeContext/typedefs';
import styles from '@/components/platform/PlatformThemeSwitcher/PlatformThemeSwitcher.module.scss';
import { ThemeIllustration } from '@/components/platform/PlatformThemeSwitcher/ThemeIllustration';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';

type Props = {
  selectorPreference: ColorThemePreference;
  chosenThemePreference: ColorThemePreference;
  selectThemePreference: (theme: ColorThemePreference) => void;
};

const LABEL_CODE_PER_PREFERENCE: Record<ColorThemePreference, string> = {
  [ColorThemePreference.Light]: 'theme_preference.light',
  [ColorThemePreference.Dark]: 'theme_preference.dark',
  [ColorThemePreference.System]: 'theme_preference.system',
};

export const PlatformThemeSelector: FC<Props> = memo(({
  selectorPreference,
  chosenThemePreference,
  selectThemePreference,
}) => {
  const { t } = useTranslation([I18N_CODES.profile]);

  const handleClick = useCallback(() => (
    selectThemePreference(selectorPreference)
  ), [
    selectThemePreference,
    selectorPreference,
  ]);

  return (
    <button
      key={selectorPreference}
      onClick={handleClick}
      className={styles.themeItem}
      data-qa={`${LABEL_CODE_PER_PREFERENCE[selectorPreference]}-button`}
    >
      <ThemeIllustration
        colorThemePreference={selectorPreference}
        isActive={chosenThemePreference === selectorPreference}
      />

      <span
        className={cn(
          typography.platformTextSmall,
          styles.textItem,
          'c-text-platform-default',
        )}
      >
        {t(`${I18N_CODES.profile}:${LABEL_CODE_PER_PREFERENCE[selectorPreference]}`)}
      </span>
    </button>
  );
});
