import { type FC } from 'react';
import { memo } from 'react';
import { ColorThemePreference } from '@/components/common/ThemeContext/typedefs';
import { cn } from '@/lib';
import {
  LightThemeIllustration,
} from '@/components/platform/PlatformThemeSwitcher/ThemeIllustration/LightThemeIllustration';
import {
  DarkThemeIllustration,
} from '@/components/platform/PlatformThemeSwitcher/ThemeIllustration/DarkThemeIllustration';
import {
  SystemThemeIllustration,
} from '@/components/platform/PlatformThemeSwitcher/ThemeIllustration/SystemThemeIllustration';
import styles from './ThemeIllustration.module.scss';

type Props = {
  isActive: boolean;
  colorThemePreference: ColorThemePreference;
};

const CLASSNAMES_PER_ILLUSTRATION = {
  [ColorThemePreference.Light]: cn(
    styles.illustration,
    styles.illustrationWithoutBorder,
  ),
  [ColorThemePreference.Dark]: cn(
    styles.illustration,
    styles.illustrationWithoutBorder,
  ),
  [ColorThemePreference.System]: cn(
    styles.illustration,
    styles.illustrationWithDefaultBorder,
  ),
};

const ILLUSTRATION_PER_THEME = {
  [ColorThemePreference.Light]: LightThemeIllustration,
  [ColorThemePreference.Dark]: DarkThemeIllustration,
  [ColorThemePreference.System]: SystemThemeIllustration,
};

export const ThemeIllustration: FC<Props> = memo(({
  isActive,
  colorThemePreference,
}) => {
  const Illustration = ILLUSTRATION_PER_THEME[colorThemePreference];

  const className = cn(
    CLASSNAMES_PER_ILLUSTRATION[colorThemePreference],
    {
      [styles.active]: isActive,
    },
  );

  return (
    <Illustration className={className} />
  );
});
