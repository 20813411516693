import { type ReactElement, type ReactNode } from 'react';
import { memo } from 'react';
import { CopyButton } from '@/components/ui/CopyButton';
import { ButtonMode } from '@/components/ui/Button';
import styles from './Pre.module.scss';

interface PreProps {
  children: ReactNode & ReactNode[];
}

const getNodeContent = (children: ReactNode & ReactNode[]): string | null => {
  try {
    const firstChild = children[0] as ReactElement;

    return firstChild.props.children[0];
  } catch {
    return null;
  }
};

export const Pre = memo<PreProps>(({ children }) => {
  const content = getNodeContent(children);

  return (
    <div className={styles.container}>
      <pre className={styles.pre} data-qa='code-block'>
        {children}
      </pre>

      {content && (
        <div className={styles.copyButtonWrapper} data-qa='copy-code-button'>
          <CopyButton
            content={content}
            mode={ButtonMode.TransparentLight}
            className={styles.copyButton}
          />
        </div>
      )}
    </div>
  );
});
