import { memo } from 'react';
import { type FCThemeIllustration } from '@/components/platform/PlatformThemeSwitcher/ThemeIllustration/typedefs';

export const LightThemeIllustration: FCThemeIllustration = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="46"
    viewBox="0 0 64 46"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_70_2421)">
      <mask
        id="mask0_70_2421"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="64"
        height="46"
      >
        <rect width="64" height="46" rx="8" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_70_2421)">
        <rect width="64" height="46" fill="#F5F6FA" />
        <rect
          x="8.76667"
          y="7.76667"
          width="70.5333"
          height="44.4667"
          rx="5.23333"
          fill="white"
          stroke="#EBEEF5"
          strokeWidth="1.53333"
        />
        <rect
          x="14.9"
          y="13.9"
          width="16.8667"
          height="38.3333"
          rx="1.23333"
          fill="#F5F6FA"
          stroke="#EBEEF5"
          strokeWidth="1.53333"
        />
        <rect
          x="36.3668"
          y="13.9"
          width="16.8667"
          height="38.3333"
          rx="1.23333"
          fill="#F5F6FA"
          stroke="#EBEEF5"
          strokeWidth="1.53333"
        />
        <rect
          x="57.8333"
          y="13.9"
          width="16.8667"
          height="38.3333"
          rx="1.23333"
          fill="#F5F6FA"
          stroke="#EBEEF5"
          strokeWidth="1.53333"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_70_2421">
        <rect width="64" height="46" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
