import { memo } from 'react';
import { type MateMarkdownProps } from '@/components/platform/Markdown/Markdown.typedefs';
import { Editable } from '@/components/ui/Editable';
import { MateMarkdown } from '@/components/platform/Markdown';

export const EditableMateMarkdown = memo<MateMarkdownProps>((props) => (
  <Editable>
    <MateMarkdown {...props} />
  </Editable>
));

EditableMateMarkdown.displayName = 'EditableMateMarkdown';
