import { memo } from 'react';
import { type FCThemeIllustration } from '@/components/platform/PlatformThemeSwitcher/ThemeIllustration/typedefs';

export const SystemThemeIllustration: FCThemeIllustration = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66"
    height="48"
    viewBox="0 0 66 48"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_13_9470)">
      <mask id="mask0_13_9470" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="51" height="46">
        <path d="M51.5 1L15 47H1V1H51.5Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13_9470)">
        <g clipPath="url(#clip1_13_9470)">
          <mask
            id="mask1_13_9470"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="64"
            height="46"
          >
            <rect x="1" y="1" width="64" height="46" rx="8" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask1_13_9470)">
            <rect x="1" y="1" width="64" height="46" fill="#191A1F" />
            <rect
              x="9.76667"
              y="8.76667"
              width="70.5333"
              height="44.4667"
              rx="5.23333"
              fill="#202227"
              stroke="#353840"
              strokeWidth="1.53333"
            />
            <rect
              x="15.9"
              y="14.9"
              width="16.8667"
              height="38.3333"
              rx="1.23333"
              fill="#353840"
              stroke="#353840"
              strokeWidth="1.53333"
            />
            <rect
              x="37.3668"
              y="14.9"
              width="16.8667"
              height="38.3333"
              rx="1.23333"
              fill="#353840"
              stroke="#353840"
              strokeWidth="1.53333"
            />
          </g>
        </g>
      </g>
      <mask id="mask2_13_9470" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="15" y="1" width="50" height="46">
        <path d="M51.5 1L15 47H65V1H51.5Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask2_13_9470)">
        <g clipPath="url(#clip2_13_9470)">
          <mask
            id="mask3_13_9470"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="64"
            height="46"
          >
            <rect x="1" y="1" width="64" height="46" rx="8" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask3_13_9470)">
            <rect x="1" y="1" width="64" height="46" fill="#F5F6FA" />
            <rect
              x="9.76667"
              y="8.76667"
              width="70.5333"
              height="44.4667"
              rx="5.23333"
              fill="white"
              stroke="#EBEEF5"
              strokeWidth="1.53333"
            />
            <rect
              x="15.9"
              y="14.9"
              width="16.8667"
              height="38.3333"
              rx="1.23333"
              fill="#F5F6FA"
              stroke="#EBEEF5"
              strokeWidth="1.53333"
            />
            <rect
              x="37.3668"
              y="14.9"
              width="16.8667"
              height="38.3333"
              rx="1.23333"
              fill="#F5F6FA"
              stroke="#EBEEF5"
              strokeWidth="1.53333"
            />
            <rect
              x="58.8333"
              y="14.9"
              width="16.8667"
              height="38.3333"
              rx="1.23333"
              fill="#F5F6FA"
              stroke="#EBEEF5"
              strokeWidth="1.53333"
            />
          </g>
        </g>
      </g>
    </g>
    <rect x="0.5" y="0.5" width="65" height="47" rx="8.5" stroke="var(--c-illustration-border)" />
    <defs>
      <clipPath id="clip0_13_9470">
        <rect x="1" y="1" width="64" height="46" rx="8" fill="white" />
      </clipPath>
      <clipPath id="clip1_13_9470">
        <rect x="1" y="1" width="64" height="46" rx="8" fill="white" />
      </clipPath>
      <clipPath id="clip2_13_9470">
        <rect x="1" y="1" width="64" height="46" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
